import { get } from './axios';

export const getXProfile = async () => {
  try {
    const response = await get(`/social-media/x/profile`);
    return response.data;
  } catch (error) {
    console.error('Error fetching X profile:', error);
    throw error;
  }
};
