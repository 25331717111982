import { get, post } from './axios';

export const getInvestments = async (userId) => {
  try {
    const response = await get(`investments/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching investments:', error);
    throw error;
  }
};

export const createInvestment = async (investment) => {
  try {
    const response = await post('/investments', investment);
    return response.data;
  } catch (error) {
    console.error('Error creating investment:', error);
    throw error;
  }
};

export const getInvestmentsReport = async (userId) => {
  try {
    const response = await get(`investments/${userId}/reports`);
    return response.data;
  } catch (error) {
    console.error('Error fetching investments:', error);
    throw error;
  }
};
