import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';
import { 
  Favorite as LikeIcon,
  Repeat as RetweetIcon,
  ChatBubble as ReplyIcon,
  X as XIcon
} from '@mui/icons-material';

export const XProfile = memo(({ profile, loading }) => {
  const [totalEngagement, setTotalEngagement] = useState({ likes: 0, retweets: 0, replies: 0 });

  useEffect(() => {
    if (!profile?.recentTweets) return;
    
    const engagement = profile.recentTweets.reduce((acc, tweet) => ({
      likes: acc.likes + tweet.likeCount,
      retweets: acc.retweets + tweet.retweetCount,
      replies: acc.replies + tweet.replyCount,
    }), { likes: 0, retweets: 0, replies: 0 });

    setTotalEngagement(engagement);
  }, [profile]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', gap: 5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 4 }}>        
        <XIcon />
        <Typography variant="h6">(Twitter) Activity</Typography>
        {loading && <CircularProgress size={24} />}
      </Box>

      {!loading && profile && (
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          gap: 10
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-around', 
            width: '100%',
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{profile.followersCount.toLocaleString()}</Typography>
              <Typography variant="body2" color="text.secondary">Followers</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{profile.tweetCount.toLocaleString()}</Typography>
              <Typography variant="body2" color="text.secondary">Tweets</Typography>
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-around', 
            width: '100%',
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                <LikeIcon />
                <Typography variant="h5">{totalEngagement.likes.toLocaleString()}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">Total Likes</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                <RetweetIcon />
                <Typography variant="h5">{totalEngagement.retweets.toLocaleString()}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">Total Retweets</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                <ReplyIcon />
                <Typography variant="h5">{totalEngagement.replies.toLocaleString()}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">Total Replies</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});

XProfile.propTypes = {
  profile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    followersCount: PropTypes.number.isRequired,
    followingCount: PropTypes.number.isRequired,
    tweetCount: PropTypes.number.isRequired,
    recentTweets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      likeCount: PropTypes.number.isRequired,
      retweetCount: PropTypes.number.isRequired,
      replyCount: PropTypes.number.isRequired,
    })).isRequired,
  }),
  loading: PropTypes.bool.isRequired,
};

XProfile.displayName = 'XProfile'; 