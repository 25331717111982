/** @jsxImportSource @emotion/react */
import { Navigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { FinanceOverview } from './components/FinanceOverview';
import { UpcomingEvents } from './components/UpcomingEvents';
import { SocialMedia } from './components/SocialMedia';
import AnimatedCard from '../../components/AnimatedCard';
import { getEvents } from '../../api/events';
import { getXProfile } from '../../api/socialMedia';
import { useIntegrations } from '../../hooks/useIntegrations';
import { IntegrationStatus } from '../../constants/integrations';

const TARGET_GROWTH_PERCENTAGE = 30;

const netWorthData = [
    { date: new Date(2024, 0, 1), value: 10000 },
    { date: new Date(2024, 1, 1), value: 12340 },
    { date: new Date(2024, 2, 1), value: 15000 },
    { date: new Date(2024, 3, 1), value: 14500 },
    { date: new Date(2024, 4, 1), value: 15000 },
    { date: new Date(2024, 5, 1), value: 14000 },
    { date: new Date(2024, 6, 1), value: 14500 },
    { date: new Date(2024, 7, 1), value: 16000 },
    { date: new Date(2024, 8, 1), value: 15000 },
    { date: new Date(2024, 9, 1), value: 13500 },
    { date: new Date(2024, 10, 1), value: 10000 },
    { date: new Date(2024, 11, 1), value: 10000 },
    { date: new Date(2024, 12, 1), value: 13500 },
  ]; 

const assetClassData = [
  { id: 0, value: 53.01, label: 'Fixed Income' },
  { id: 1, value: 44.56, label: 'Equity' },
  { id: 2, value: 2.43, label: 'Cash' },
];

const sectorData = [
  { id: 0, value: 28.8, label: 'Financials' },
  { id: 1, value: 14.2, label: 'Information Tech' },
  { id: 2, value: 11.2, label: 'Government' },
  { id: 3, value: 9.2, label: 'Consumer Discrete' },
  { id: 4, value: 6.7, label: 'Industrials' },
  { id: 5, value: 5.8, label: 'Health Care' },
  { id: 6, value: 4.7, label: 'Multi-Sector' },
  { id: 7, value: 4.2, label: 'Consumer Staple' },
  { id: 8, value: 3.5, label: 'Utilities' },
];

const Home = () => {
  const { currentUser, isSetupComplete } = useAuth();
  const { googleCalendarStatus, xStatus } = useIntegrations();
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [xProfile, setXProfile] = useState(null);
  const [xProfileLoading, setXProfileLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      if (googleCalendarStatus !== IntegrationStatus.ACTIVE) return;
      
      try {
        setEventsLoading(true);
        const response = await getEvents();
        setEvents(response.data);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      } finally {
        setEventsLoading(false);
      }
    };

    const fetchXProfile = async () => {
      if (xStatus !== IntegrationStatus.ACTIVE) return;
      
      try {
        setXProfileLoading(true);
        const response = await getXProfile();
        setXProfile(response.data);
      } catch (error) {
        console.error('Failed to fetch X profile:', error);
      } finally {
        setXProfileLoading(false);
      }
    };
    
    fetchEvents();
    fetchXProfile();
  }, [currentUser.uid, googleCalendarStatus, xStatus]);

  if (!isSetupComplete) {
    return <Navigate to="/profile-setup" replace />;
  }

  return (
    <Box padding={4}>
      <Box
        mb={4}
      >
        <Typography align="center" variant="h4" component="h1" fontWeight="bold">
          {currentUser.name}&apos;s Statistics
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid size={6}>
          <AnimatedCard>
            <FinanceOverview 
              netWorthData={netWorthData}
              targetGrowthPercentage={TARGET_GROWTH_PERCENTAGE}
              assetClassData={assetClassData}
              sectorData={sectorData}
            />
          </AnimatedCard>
        </Grid>

        <Grid size={6}>
          <AnimatedCard>
            <UpcomingEvents events={events} loading={eventsLoading} />
          </AnimatedCard>
        </Grid>

        <Grid size={6}>
          <AnimatedCard>
            <SocialMedia xProfile={xProfile} xProfileLoading={xProfileLoading} />
          </AnimatedCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
