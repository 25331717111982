import { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

import PhotoUpload from '../PhotoUpload';

const ProfileForm = ({ isEditing, onSubmit, initialData = {} }) => {
  const [name, setName] = useState(initialData.name || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [photo, setPhoto] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePhotoSelect = (file) => {
    setPhoto(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      await onSubmit({ name, description, photo });
    } catch (error) {
      setErrorMessage('Failed to save profile. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
        <Box
      component="main"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box
        width="24rem"
        padding={4}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        border="1px solid #e2e8f0"
        borderRadius="12px"
      >
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ width: '100%', maxWidth: 400 }}
    >
      <Typography variant="h5" component="h2" mb={3}>
        {isEditing ? 'Edit Profile' : 'Complete Your Profile'}
      </Typography>
      <TextField
        fullWidth
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4}
        required
      />
      <PhotoUpload onPhotoSelect={handlePhotoSelect} />
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box mt={3}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitting}
          sx={{ mb: isEditing ? 2 : 0 }}
        >
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : isEditing ? (
            'Save Changes'
          ) : (
            'Complete Setup'
          )}
        </Button>
        {isEditing && (
          <Button variant="outlined" onClick={() => onSubmit(null)} fullWidth>
            Cancel
          </Button>
        )}
        </Box>
      </Box>
    </Box>
 </Box>
  );
};

ProfileForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ProfileForm;
