/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doSignInWithEmailAndPassword } from '../../../firebase/auth';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import profenixLogo from '../../../assets/proffenix_logo_no_bg.png';

const FIREBASE_INVALID_CREDENTIAL_ERROR =
  'Firebase: Error (auth/invalid-credential).';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isSigningIn) return;

    setIsSigningIn(true);
    try {
      await doSignInWithEmailAndPassword(email, password);
    } catch (error) {
      if (error.message === FIREBASE_INVALID_CREDENTIAL_ERROR) {
        setErrorMessage('Invalid credentials');
      } else {
        setErrorMessage('Something went wrong');
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <Box
      component="main"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box
        width="24rem"
        padding={4}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        border="1px solid #e2e8f0"
        borderRadius="12px"
      >
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <img 
            src={profenixLogo} 
            alt="Profenix Logo" 
            style={{ 
              width: '80%', 
              maxWidth: '200px',
              marginBottom: '20px'
            }} 
          />
        </Box>

        <Typography variant="h5" fontWeight="600" textAlign="center" mb={2}>
          Welcome Back
        </Typography>

        <form onSubmit={onSubmit}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessage.includes('email')}
          />

          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errorMessage.includes('password')}
            autoComplete="current-password"
          />

          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSigningIn}
          >
            {isSigningIn ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </form>

        <Typography align="center" sx={{ mt: 1 }}>
          <Link
            to="/password-reset"
            style={{ textDecoration: 'none', color: '#1976d2' }}
          >
            Forgot Password?
          </Link>
        </Typography>

        <Typography align="center" variant="body2" sx={{ mt: 2 }}>
          Don&apos;t have an account?{' '}
          <Link
            to="/register"
            style={{
              fontWeight: 'bold',
              textDecoration: 'none',
              color: '#1976d2',
            }}
          >
            Sign up
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
