import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { 
  Twitter as TwitterIcon, 
  Instagram as InstagramIcon,
  Share as ShareIcon,
  NavigateBefore as PrevIcon,
  NavigateNext as NextIcon,
} from '@mui/icons-material';

import { useIntegrations } from '../../../hooks/useIntegrations';
import { XProfile } from './social/XProfile';
import { InstagramProfile } from './social/InstagramProfile';
import { IntegrationStatus } from '../../../constants/integrations';
import { NoSocialMediaIntegration } from './social/NoSocialMediaIntegration';

export const SocialMedia = memo(({ xProfile, xProfileLoading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { xStatus, instagramStatus } = useIntegrations();
  
  const getActivePlatforms = () => {
    const platforms = [];
    
    if (xStatus === IntegrationStatus.ACTIVE) {
      if (xProfileLoading || xProfile) {
        platforms.push({ 
          icon: <TwitterIcon />, 
          label: 'X', 
          component: <XProfile profile={xProfile} loading={xProfileLoading} /> 
        });
      }
    }
    
    if (instagramStatus === IntegrationStatus.ACTIVE) {
      platforms.push({
        icon: <InstagramIcon />,
        label: 'Instagram',
        component: <InstagramProfile />
      });
    }
    
    return platforms;
  };

  const handleChangeIndex = (index) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(platforms.length - 1, prev + 1));
  };

  const platforms = getActivePlatforms();

  if (platforms.length === 0) {
    return <NoSocialMediaIntegration />
  }

  if (platforms.length === 1) {
    return (
      <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <ShareIcon />
          <Typography variant="h6">Social Media</Typography>
        </Box>
        {platforms[0].component}
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
        <ShareIcon />
        <Typography variant="h6">Social Media</Typography>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton 
          onClick={handlePrev} 
          disabled={currentIndex === 0}
        >
          <PrevIcon />
        </IconButton>
        <SwipeableViews index={currentIndex} onChangeIndex={handleChangeIndex}>
          {platforms.map((platform, index) => (
            <div key={index}>{platform.component}</div>
          ))}
        </SwipeableViews>
        <IconButton 
          onClick={handleNext} 
          disabled={currentIndex === platforms.length - 1}
        >
          <NextIcon />
        </IconButton>
      </Box>
    </Paper>
  );
});

SocialMedia.propTypes = {
  xProfile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    followersCount: PropTypes.number.isRequired,
    followingCount: PropTypes.number.isRequired,
    tweetCount: PropTypes.number.isRequired,
    recentTweets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      likeCount: PropTypes.number.isRequired,
      retweetCount: PropTypes.number.isRequired,
      replyCount: PropTypes.number.isRequired,
    })).isRequired,
  }),
  xProfileLoading: PropTypes.bool.isRequired,
};

SocialMedia.displayName = 'SocialMedia';
