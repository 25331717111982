import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const uploadProfilePhoto = async (file, userId) => {
  const storageRef = ref(storage, `profilePhotos/${userId}`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    return snapshot;
  } catch (error) {
    console.error('Error uploading photo:', error);
    throw error;
  }
};

export const getProfilePhotoUrl = async (userId) => {
  const storageRef = ref(storage, `profilePhotos/${userId}`);
  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error('Error getting photo URL:', error);
    return null;
  }
};

