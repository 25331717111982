import { post } from './axios';

export const requestAssistance = async () => {
  try {
    const response = await post(`travel/assistance`);
    return response.data;
  } catch (error) {
    console.error('Error requesting assistance:', error);
    throw error;
  }
};