import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIntegrations } from '../../../hooks/useIntegrations';


export const GoogleCalendarCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleGoogleCalendarCallback, checkGoogleCalendarIntegration } = useIntegrations();
  const hasProcessed = useRef(false);

  useEffect(() => {
    const callback = async () => {
      if (hasProcessed.current) return;
      
      const code = searchParams.get('code');
      
      if (!code) {
        navigate('/');
        return;
      }

      try {
        hasProcessed.current = true;
        await handleGoogleCalendarCallback(code);
        await checkGoogleCalendarIntegration();
        navigate('/settings');
      } catch (error) {
        console.error('Failed to handle X callback:', error);
        navigate('/');
      }
    };

    callback();
  }, [searchParams, navigate, handleGoogleCalendarCallback, checkGoogleCalendarIntegration]);

  return <></>;
};
