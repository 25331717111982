import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { Instagram as InstagramIcon } from '@mui/icons-material';

export const InstagramProfile = memo(() => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 4 }}>
        <InstagramIcon />
        <Typography variant="h6">Instagram Activity</Typography>
      </Box>
      <Typography>Instagram integration coming soon...</Typography>
    </Box>
  );
});

InstagramProfile.displayName = 'InstagramProfile'; 