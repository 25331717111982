import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/useAuth';
import { useGoogleCalendarIntegration } from './googleCalendar';
import { useXIntegration } from './x';
import { IntegrationStatus } from '../../constants/integrations';

export const IntegrationsContext = createContext();

export const IntegrationsProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  const googleCalendar = useGoogleCalendarIntegration(currentUser);
  const x = useXIntegration(currentUser);

  useEffect(() => {
    const checkIntegrations = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        await Promise.all([
          googleCalendar.checkIntegration(),
          x.checkIntegration()
        ]);
      } finally {
        setLoading(false);
      }
    };

    checkIntegrations();
  }, [currentUser]);

  const value = {
    googleCalendarStatus: googleCalendar.status,
    googleCalendarError: googleCalendar.error,
    xStatus: x.status,
    xError: x.error,
    instagramStatus: IntegrationStatus.NEVER_INTEGRATED,
    instagramError: '',
    outlookCalendarStatus: IntegrationStatus.NEVER_INTEGRATED,
    outlookCalendarError: '',
    loading: loading || googleCalendar.loading || x.loading,
    initiateGoogleCalendarAuth: googleCalendar.initiateAuth,
    handleGoogleCalendarCallback: googleCalendar.handleCallback,
    checkGoogleCalendarIntegration: googleCalendar.checkIntegration,
    initiateXAuth: x.initiateAuth,
    handleXCallback: x.handleCallback,
    checkXIntegration: x.checkIntegration,
  };

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};

IntegrationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};