import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { useAuth } from '../../hooks/useAuth';
import { getInvestmentsReport } from '../../api/investments';
import InvestmentTooltip from './InvestmentTooltip';
import AnimatedCard from '../../components/AnimatedCard';

const Finance = () => {
  const { currentUser } = useAuth();
  const [investmentsReport, setInvestmentsReport] = useState(null);

  const sortInvestmentData = (data) => ({
    ...data,
    categoryStats: [...(data.categoryStats || [])].sort((a, b) => 
      a.category.localeCompare(b.category)
    ),
    investmentAmounts: [...(data.investmentAmounts || [])].sort((a, b) => 
      b.amount - a.amount
    )
  });

  useEffect(() => {
    getInvestmentsReport(currentUser.id).then(response => {
      setInvestmentsReport(sortInvestmentData(response.data));
    });
  }, [currentUser.id]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Finance Dashboard
        </Typography>
        <Grid container spacing={4}>
          <Grid size={4}>
            <AnimatedCard animated={false}>
              <Typography variant="h6" gutterBottom>
                Total Balance
              </Typography>
              <Typography variant="h4">
                ${investmentsReport?.summary?.totalBalance?.toLocaleString() || 0}
              </Typography>
            </AnimatedCard>
          </Grid>
          <Grid size={4}>
            <AnimatedCard animated={false}>
              <Typography variant="h6" gutterBottom>
                Average Yield
              </Typography>
              <Typography variant="h4">
                {investmentsReport?.summary?.averageYield || 0}%
              </Typography>
            </AnimatedCard>
          </Grid>
          <Grid size={4}>
            <AnimatedCard animated={false}>
              <Typography variant="h6" gutterBottom>
                Yearly Income
              </Typography>
              <Typography variant="h4">
                ${investmentsReport?.summary?.yearlyIncome?.toLocaleString() || 0}
              </Typography>
            </AnimatedCard>
          </Grid>
        </Grid>

        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          Investment Reports
        </Typography>
        <Grid container spacing={4}>
          <Grid size={6}>
            <AnimatedCard animated={false}>
              <Typography variant="h6" gutterBottom textAlign="center">
                Investment Allocation by Category
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart 
                  data={investmentsReport?.categoryStats || []}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="totalAmount" fill="#8884d8" name="Total Amount ($)" />
                </BarChart>
              </ResponsiveContainer>
            </AnimatedCard>
          </Grid>
          <Grid size={6}>
            <AnimatedCard animated={false}>
              <Typography variant="h6" gutterBottom textAlign="center">
                Average Yield by Category
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart 
                  data={investmentsReport?.categoryStats || []}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="averageYield" fill="#82ca9d" name="Yield %" />
                </BarChart>
              </ResponsiveContainer>
            </AnimatedCard>
          </Grid>
          <Grid size={12}>
            <AnimatedCard animated={false}>
              <Typography variant="h6" gutterBottom textAlign="center">
                Individual Investment
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart 
                  data={investmentsReport?.investmentAmounts || []}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={InvestmentTooltip} />
                  <Legend />
                  <Bar dataKey="amount" fill="#8884d8" name="Amount ($)" />
                </BarChart>
              </ResponsiveContainer>
            </AnimatedCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Finance;
