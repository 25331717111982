import { Box, Paper } from '@mui/material';
import PropTypes from 'prop-types';

const AnimatedCard = ({ children, animated = true }) => {
  return (
    <Box>
      <Paper
        sx={{
          p: 3,
          height: '100%',
          borderRadius: 6,
          position: 'relative',
          transition: animated ? 'all 0.9s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'none',
          '&:hover': {
            transform: animated ? 'translateY(-8px) scale(1.01)' : 'none',
            boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
          },
          '&:active': {
            transform: animated ? 'translateY(-2px) scale(0.99)' : 'none',
          }
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

AnimatedCard.propTypes = {
  children: PropTypes.node.isRequired,
  animated: PropTypes.bool,
};

export default AnimatedCard; 