import { useState } from 'react';
import { getGoogleAuthUrl, handleGoogleCallback, checkGoogleCalendarIntegration } from '../../api/integrations';
import { IntegrationStatus } from '../../constants/integrations';

export const useGoogleCalendarIntegration = (currentUser) => {
  const [googleCalendarStatus, setGoogleCalendarStatus] = useState(IntegrationStatus.ACTIVE);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkIntegration = async () => {
    if (!currentUser) return;
    
    setLoading(true);
    try {
      const { data } = await checkGoogleCalendarIntegration();
      setGoogleCalendarStatus(data.status);
      setError(null);
    } catch (error) {
      console.error('Failed to check Google Calendar integration status:', error);
      setError('Failed to check Google Calendar integration status');
    } finally {
      setLoading(false);
    }
  };

  const initiateAuth = async () => {
    try {
      const { data } = await getGoogleAuthUrl();
      setError(null);
      window.location.href = data.url;
    } catch (error) {
      console.error('Google Calendar integration error:', error);
      setError('Failed to initiate Google Calendar integration');
    }
  };

  const handleCallback = async (code) => {
    setLoading(true);
    try {
      const { data } = await handleGoogleCallback(code);
      return data;
    } catch (error) {
      console.error('Failed to complete Google Calendar integration:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    status: googleCalendarStatus,
    error,
    loading,
    checkIntegration,
    initiateAuth,
    handleCallback,
  };
}; 