/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth';
import { useAuth } from '../../../hooks/useAuth';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';

const FIREBASE_EMAIL_ALREADY_USED_MESSAGE =
  'Firebase: Error (auth/email-already-in-use).';

const Register = () => {
  const { setIsSetupComplete } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [email, password, confirmPassword]);

  const validateForm = () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return false;
    }
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters.');
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsRegistering(true);

    try {
      await doCreateUserWithEmailAndPassword(email, password);
      setIsSetupComplete(false);
    } catch (error) {
      if (error.message === FIREBASE_EMAIL_ALREADY_USED_MESSAGE) {
        setErrorMessage('Email already in use');
      } else {
        setErrorMessage('Something went wrong');
      }
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <Box
      component="main"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box
        width="24rem"
        padding={4}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        border="1px solid #e2e8f0"
        borderRadius="12px"
      >
        <Typography variant="h5" fontWeight="600" textAlign="center" mb={2}>
          Create a New Account
        </Typography>

        <form onSubmit={onSubmit}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessage.includes('email')}
          />

          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errorMessage.includes('Password')}
            disabled={isRegistering}
          />

          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={errorMessage.includes('match')}
            disabled={isRegistering}
          />

          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isRegistering}
          >
            {isRegistering ? <CircularProgress size={24} /> : 'Sign Up'}
          </Button>

          <Typography textAlign="center" variant="body2">
            Already have an account?{' '}
            <Link
              to="/login"
              style={{
                fontWeight: 'bold',
                textDecoration: 'none',
                color: '#1976d2',
              }}
            >
              Sign in
            </Link>
          </Typography>
        </form>
      </Box>
    </Box>
  );
};

export default Register;
