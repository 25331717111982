/** @jsxImportSource @emotion/react */
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const UnprotectedRoute = () => {
  const { currentUser, isSetupComplete } = useAuth();
  const route = isSetupComplete ? '/home' : '/profile-setup';
  return currentUser ? <Navigate to={route} replace /> : <Outlet />;
};

export default UnprotectedRoute;
