import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF4B26',
      light: '#FF7842',
      dark: '#CC3C1E',
    },
    secondary: {
      main: '#333333',
      light: '#4D4D4D',
      dark: '#1A1A1A',
    },
    background: {
      default: '#1A1A1A',
      paper: '#282c34',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B3B3B3',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#282c34',
          backgroundImage: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#282c34',
          backgroundImage: 'none',
        },
      },
    },
  },
}); 
