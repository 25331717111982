import { useState } from 'react';
import { Box, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';

const PhotoUpload = ({ onPhotoSelect }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      onPhotoSelect(file);
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="photo-upload"
        type="file"
        onChange={handleFileSelect}
      />
      <label htmlFor="photo-upload">
        <Button
          variant="outlined"
          component="span"
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ mb: 2 }}
        >
          {selectedFile ? 'Change Photo' : 'Upload Photo'}
        </Button>
      </label>
      {previewUrl && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <img
            src={previewUrl}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
          />
        </Box>
      )}
    </Box>
  );
};

PhotoUpload.propTypes = {
  onPhotoSelect: PropTypes.func.isRequired,
};

export default PhotoUpload;
