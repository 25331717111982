import { memo } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { 
    Settings as SettingsIcon,
    Share as ShareIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const NoSocialMediaIntegration = memo(() => {
    const navigate = useNavigate();

    return (
      <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <ShareIcon />
          <Typography variant="h6">Social Media</Typography>
        </Box>
        <Box sx={{ 
          flex: 1, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'center'
        }}>
          <Typography variant="h6">No Active Social Media Integrations</Typography>
          <Typography variant="body2" color="text.secondary">
            Connect your social media accounts in the settings to see your activity here.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => navigate('/settings')}
            sx={{ mt: 2 }}
          >
            Go to Settings
          </Button>
        </Box>
      </Paper>
    );
});

NoSocialMediaIntegration.displayName = 'NoSocialMediaIntegration';
