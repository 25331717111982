import { get } from './axios';

export const getGoogleAuthUrl = async () => {
  try {
    const { data } = await get('/integrations/google/calendar/auth');
    return data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const handleGoogleCallback = async (code) => {
  try {
    const {data} = await get(`/integrations/google/calendar/callback?code=${code}`);
    return data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const checkGoogleCalendarIntegration = async () => {
  try {
    const { data } = await get(`/integrations/google/calendar/status`);
    return data;
  } catch (error) {
    console.error('Error checking integration status:', error);
    throw error;
  }
};

export const getXAuthUrl = async () => {
  try {
    const { data } = await get('/integrations/x/auth');
    return data;
  } catch (error) {
    console.error('Error getting X auth URL:', error);
    throw error;
  }
};

export const handleXCallback = async (code, state) => {
  try {
    const { data } = await get(`/integrations/x/callback?code=${code}&state=${state}`);
    return data;
  } catch (error) {
    console.error('Error handling X callback:', error);
    throw error;
  }
};

export const checkXIntegration = async () => {
  try {
    const { data } = await get(`/integrations/x/status`);
    return data;
  } catch (error) {
    console.error('Error checking X integration status:', error);
    throw error;
  }
};
