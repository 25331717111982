import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { 
  Home as HomeIcon, 
  AccountBalance as FinanceIcon, 
  Logout as LogoutIcon,
  Edit as EditIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import profenixLogo from '../../assets/proffenix_logo_no_bg.png';
import { doSignOut } from '../../firebase/auth';

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  const list = () => (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <List>
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <img 
            src={profenixLogo} 
            alt="Profenix Logo" 
            style={{ 
              width: '100%', 
              marginBottom: '-40px',
              marginTop: '-30px'
            }} 
          />
        </Box>
        <ListItem 
          button 
          onClick={() => handleNavigation('/home')}
          sx={{ borderRadius: 2, m: 1 }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem 
          button 
          onClick={() => handleNavigation('/finance')}
          sx={{ borderRadius: 2, m: 1 }}
        >
          <ListItemIcon>
            <FinanceIcon />
          </ListItemIcon>
          <ListItemText primary="Finance" />
        </ListItem>
        {/* <ListItem 
          button 
          onClick={() => handleNavigation('/calendar')}
          sx={{ borderRadius: 2, m: 1 }}
        >
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText primary="Calendar" />
        </ListItem> */}
      </List>
      
      <List sx={{ marginTop: 'auto' }}>
        <ListItem 
          button 
          onClick={() => handleNavigation('/edit-profile')}
          sx={{ borderRadius: 2, m: 1 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit Profile" />
        </ListItem>
        <ListItem 
          button 
          onClick={() => handleNavigation('/settings')}
          sx={{ borderRadius: 2, m: 1 }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem 
          button 
          onClick={doSignOut}
          sx={{ borderRadius: 2, m: 1 }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 250,
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
        },
      }}
      transitionDuration={300}
    >
      {list()}
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Sidebar;
