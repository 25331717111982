import PropTypes from 'prop-types';

const InvestmentTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  return (
    <div style={{
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      padding: '12px',
      border: '1px solid #ccc',
      borderRadius: '6px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
    }}>
      <p style={{ 
        margin: '0 0 8px 0',
        fontWeight: 'bold',
        borderBottom: '1px solid #eee',
        paddingBottom: '4px'
      }}>{label}</p>
      <p style={{ 
        margin: '4px 0',
        color: '#666',
        fontSize: '14px'
      }}>
        <span style={{ color: '#8884d8', fontWeight: 'bold' }}>Amount:</span> ${payload[0].value.toLocaleString()}
      </p>
      <p style={{ 
        margin: '4px 0',
        color: '#666',
        fontSize: '14px'
      }}>
        <span style={{ color: '#82ca9d', fontWeight: 'bold' }}>Yield:</span> {payload[0].payload.yieldPercentage}%
      </p>
      <p style={{ 
        margin: '4px 0',
        color: '#666',
        fontSize: '14px'
      }}>
        <span style={{ color: '#ff7300', fontWeight: 'bold' }}>Category:</span> {payload[0].payload.category}
      </p>
    </div>
  );
};

InvestmentTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      payload: PropTypes.shape({
        yieldPercentage: PropTypes.number,
        category: PropTypes.string
      })
    })
  ),
  label: PropTypes.string
};

export default InvestmentTooltip; 