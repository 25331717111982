import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { CheckCircle, Refresh, Add } from '@mui/icons-material';
import { IntegrationStatus } from '../../constants/integrations';

const statusConfig = {
  [IntegrationStatus.ACTIVE]: {
    color: 'green',
    label: 'Connected',
    disabledClick: true,
    icon: <CheckCircle />
  },
  [IntegrationStatus.EXPIRED]: {
    color: '#FFA726',
    label: 'Re-connect',
    disabledClick: false,
    icon: <Refresh />
  },
  [IntegrationStatus.NEVER_INTEGRATED]: {
    color: '#FF4B26',
    label: 'Connect',
    disabledClick: false,
    icon: <Add />
  }
};

export const IntegrationButton = ({ status, onClick, disabled = false }) => {
  const config = statusConfig[status];

  return (
    <Button
      onClick={config.disabledClick ? () => {} : onClick}
      color={config.color}
      disabled={disabled}
      variant="contained"
      startIcon={disabled ? null : config.icon}
      sx={{
        textTransform: 'none',
        borderRadius: 2,
        px: 3,
        py: 1,
        opacity: (config.disabledClick) ? 0.7 : 1,
        backgroundColor: config.color,
        '&:hover': {
          cursor: config.disabledClick ? 'default' : 'pointer'
        }
      }}
    >
      {disabled ? 'Upcoming...' : config.label}
    </Button>
  );
};

IntegrationButton.propTypes = {
  status: PropTypes.oneOf(Object.values(IntegrationStatus)).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}; 
