import { Box, Typography, Alert, CircularProgress } from '@mui/material';

import { useIntegrations } from '../../hooks/useIntegrations';
import { IntegrationButton } from '../../components/IntegrationButton';
import AnimatedCard from '../../components/AnimatedCard';
import GoogleCalendarIcon from '../../assets/google_calendar_icon.png';
import OutlookIcon from '../../assets/outlook_icon.png';
import XIcon from '../../assets/x_icon.png';
import { IntegrationStatus } from '../../constants/integrations';


const Settings = () => {
  const { 
    googleCalendarStatus, 
    googleCalendarError,
    xStatus,
    xError,
    loading,
    initiateGoogleCalendarAuth, 
    initiateXAuth 
  } = useIntegrations();

  if (loading) {
    return (
      <Box padding={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      
      <AnimatedCard animated={false}>
        <Typography variant="h6" gutterBottom>
          Integrations
        </Typography>
        
        <Box sx={{ mt: 2 }}  marginBottom={5}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
              src={GoogleCalendarIcon} 
              alt="Google Calendar"
              style={{ width: '58px', height: '58px' }}
            />
            <Typography variant="subtitle1" gutterBottom>
              Google Calendar
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Sync your events with Google Calendar to manage your schedule more effectively.
          </Typography>
          
          <IntegrationButton 
            status={googleCalendarStatus || IntegrationStatus.NEVER_INTEGRATED}
            onClick={initiateGoogleCalendarAuth}
          />

          {googleCalendarError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {googleCalendarError}
            </Alert>
          )}
        </Box>
        <Box sx={{ mt: 2 }} marginBottom={5}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
              src={OutlookIcon} 
              alt="Outlook Calendar"
              style={{ width: '58px', height: '58px' }}
            />
            <Typography variant="subtitle1" gutterBottom>
              Outlook Calendar
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Sync your events with Outlook Calendar to manage your schedule more effectively.
          </Typography>
          
          <IntegrationButton 
            status={IntegrationStatus.NEVER_INTEGRATED}
            onClick={() => {}}
            disabled={true}
          />

          {googleCalendarError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {googleCalendarError}
            </Alert>
          )}
        </Box>
        <Box sx={{ mt: 2 }} marginBottom={5}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
              src={XIcon} 
              alt="X"
              style={{ width: '58px', height: '58px' }}
            />
            <Typography variant="subtitle1" gutterBottom>
              X (Twitter)
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Connect your X account to share updates and engage with your community.
          </Typography>
          
          <IntegrationButton 
            status={xStatus || IntegrationStatus.NEVER_INTEGRATED}
            onClick={initiateXAuth}
          />

          {xError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {xError}
            </Alert>
          )}
        </Box>
      </AnimatedCard>
    </Box>
  );
};

export default Settings; 