import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, IconButton, Button } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { 
  AccountBalance as FinanceIcon,
  NavigateBefore as PrevIcon,
  NavigateNext as NextIcon,
  Download as DownloadIcon
} from '@mui/icons-material';

import { NetWorthChart } from './finance/NetWorthChart';
import { AssetClassChart } from './finance/AssetClassChart';
import { SectorChart } from './finance/SectorChart';

export const FinanceOverview = memo(({ 
  netWorthData, 
  targetGrowthPercentage,
  assetClassData,
  sectorData 
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(2, prev + 1));
  };

  const handleDownloadReport = () => {
    const link = document.createElement('a');
    link.href = '/Portfolio_Overview_Sample.pdf';
    link.download = 'Portfolio Overview.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FinanceIcon />
          <Typography variant="h6">Finance Overview</Typography>
        </Box>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadReport}
          size="small"
        >
          Download Full Report
        </Button>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
          <PrevIcon />
        </IconButton>

        <SwipeableViews index={currentIndex} onChangeIndex={handleChangeIndex}>
          <NetWorthChart 
            netWorthData={netWorthData}
            targetGrowthPercentage={targetGrowthPercentage}
          />
          <AssetClassChart assetClassData={assetClassData} />
          <SectorChart sectorData={sectorData} />
        </SwipeableViews>

        <IconButton onClick={handleNext} disabled={currentIndex === 2}>
          <NextIcon />
        </IconButton>
      </Box>
    </Paper>
  );
});

FinanceOverview.propTypes = {
  netWorthData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  targetGrowthPercentage: PropTypes.number.isRequired,
  assetClassData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  sectorData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

FinanceOverview.displayName = 'FinanceOverview'; 