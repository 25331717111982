import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { auth } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getMyUser } from '../../api/users'; // Import the new API function
import { getProfilePhotoUrl } from '../../firebase/storage';

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSetupComplete, setIsSetupComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      try {
        const { data } = await getMyUser();
        const url = await getProfilePhotoUrl(user.uid);
        setCurrentUser({ ...user, firebaseId: user.uid, ...data, profilePhotoUrl: url });
        setIsSetupComplete(!!data?.email);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setCurrentUser({ ...user, firebaseId: user.uid });
        setIsSetupComplete(false);
      }
    } else {
      setCurrentUser(null);
      setIsSetupComplete(false);
    }
    setLoading(false);
  }

  const value = {
    currentUser,
    isSetupComplete,
    setIsSetupComplete,
    setCurrentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
