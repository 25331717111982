import { Navigate } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import { createUser } from '../../../api/users';
import { getProfilePhotoUrl, uploadProfilePhoto } from '../../../firebase/storage';
import ProfileForm from '../../../components/ProfileForm';

const ProfileSetup = () => {
  const { currentUser, setCurrentUser, isSetupComplete, setIsSetupComplete } = useAuth();

  const handleSubmit = async (formData) => {
    const { name, description, photo } = formData;
    let photoUrl;

    if (photo) {
      await uploadProfilePhoto(photo, currentUser.firebaseId);
      photoUrl = await getProfilePhotoUrl(currentUser.firebaseId);
    }

    await createUser({
      firebaseId: currentUser.firebaseId,
      email: currentUser.email,
      name,
      description,
    });

    setIsSetupComplete(true);
    setCurrentUser({ 
      ...currentUser, 
      name, 
      description,
      profilePhotoUrl: photoUrl || currentUser.profilePhotoUrl 
    });
  };

  if (isSetupComplete) {
    return <Navigate to="/home" replace />;
  }

  return (
    <ProfileForm isEditing={false} onSubmit={handleSubmit} />
  );
};

export default ProfileSetup;
