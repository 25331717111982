import { useState } from 'react';
import { getXAuthUrl, handleXCallback, checkXIntegration } from '../../api/integrations';
import { IntegrationStatus } from '../../constants/integrations';

export const useXIntegration = (currentUser) => {
  const [xStatus, setXStatus] = useState(IntegrationStatus.ACTIVE);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkIntegration = async () => {
    if (!currentUser) return;
    
    setLoading(true);
    try {
      const { data } = await checkXIntegration();
      setXStatus(data.status);
      setError(null);
    } catch (error) {
      console.error('Failed to check X integration status:', error);
      setError('Failed to check X integration status');
    } finally {
      setLoading(false);
    }
  };

  const initiateAuth = async () => {
    try {
      const { data } = await getXAuthUrl();
      setError(null);
      window.location.href = data.url;
    } catch (error) {
      console.error('X integration error:', error);
      setError('Failed to initiate X integration');
    }
  };

  const handleCallback = async (code, state) => {
    setLoading(true);
    try {
      const { data } = await handleXCallback(code, state);
      setError(null);
      return data;
    } catch (error) {
      console.error('Failed to complete X integration:', error);
      setError('Failed to complete X integration');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    status: xStatus,
    error,
    loading,
    checkIntegration,
    initiateAuth,
    handleCallback,
  };
}; 