import { get } from './axios';

export const getEvents = async () => {
  try {
    const response = await get(`/calendar/events`);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};
