import { useNavigate } from 'react-router-dom';
import ProfileForm from '../../components/ProfileForm';
import { useAuth } from '../../hooks/useAuth';
import { updateUser } from '../../api/users';
import { uploadProfilePhoto, getProfilePhotoUrl } from '../../firebase/storage';

const EditProfile = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    if (!formData) {
      navigate('/home');
      return;
    }

    const { name, description, photo } = formData;
    let updatedUser = { ...currentUser, name, description };

    if (photo) {
      await uploadProfilePhoto(photo, currentUser.firebaseId);
      const url = await getProfilePhotoUrl(currentUser.firebaseId);
      updatedUser.profilePhotoUrl = url;
    }

    await updateUser({ name, description });
    setCurrentUser(updatedUser);
    navigate('/home');
  };

  return (
    <ProfileForm
      isEditing={true}
      onSubmit={handleSubmit}
      initialData={{
        name: currentUser.name,
        description: currentUser.description,
      }}
    />
  );
};

export default EditProfile; 