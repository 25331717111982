import { Box, Paper, Avatar, Typography, IconButton, Button, Alert, Snackbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import Sidebar from '../Sidebar';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useState } from 'react';
import { requestAssistance } from '../../api/travel';

const Layout = () => {
  const { currentUser } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleAssistanceRequest = async () => {
    setIsLoading(true);
    try {
      await requestAssistance();
      setShowSuccess(true);
    } catch (error) {
      console.error('Error requesting assistance:', error);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      {!!currentUser && (
        <>
          <Paper 
            elevation={2}
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              zIndex: 1100,
            }}
          >
            <IconButton onClick={() => toggleDrawer(true)} sx={{ ml: 1 }}>
              <MenuIcon />
            </IconButton>
            
            <Avatar
              src={currentUser.profilePhotoUrl}
              alt={currentUser.name}
              sx={{ width: 64, height: 64 }}
            />
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" component="h1" fontWeight="bold">
                  {currentUser.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '600px' }}>
                  {currentUser.description || "No biography available."}
                </Typography>
              </Box>
              <Button 
                onClick={handleAssistanceRequest} 
                color="primary" 
                disabled={isLoading}
                sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'white',
                  '&.Mui-disabled': {
                    bgcolor: 'action.disabledBackground',
                    color: 'action.disabled'
                  }
                }}
              >
                <Typography variant="body2">
                  Request Travel Assistance
                </Typography>
              </Button>
            </Box>
          </Paper>
          
          <Sidebar open={drawerOpen} onClose={() => toggleDrawer(false)} />
        </>
      )}
      
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          overflow: 'auto',
          bgcolor: 'background.default'
        }}
      >
        <Outlet />
      </Box>
      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowSuccess(false)} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          Travel assistance request sent successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={showError}
        autoHideDuration={2000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowError(false)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          Failed to send travel assistance request. Please try again.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Layout;
