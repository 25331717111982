import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntegrations } from '../../../hooks/useIntegrations';

export const XCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleXCallback, checkXIntegration } = useIntegrations();
  const hasProcessed = useRef(false);

  useEffect(() => {
    const callback = async () => {
      if (hasProcessed.current) return;
      
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      
      if (!code || !state) {
        navigate('/');
        return;
      }

      try {
        hasProcessed.current = true;
        await handleXCallback(code, state);
        await checkXIntegration();
        navigate('/settings');
      } catch (error) {
        console.error('Failed to handle X callback:', error);
        navigate('/');
      }
    };

    callback();
  }, [searchParams, navigate, handleXCallback, checkXIntegration]);

  return <></>;
}; 